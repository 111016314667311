import React, { useState } from 'react';
import 'bulma/css/bulma.min.css'
import './app.css';

interface IPossibleAnswer {
    label: string;
    value: string;
}

interface IQProps {
    title: string;
    possibleAnswers: IPossibleAnswer[];
    onSaveAnswer: (val: string) => void;
}


const Question = (props: IQProps) => {
    const [answer, setAnswer] = useState('');

    return (
        <>
            <section className="hero is-primary has-text-centered">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title">{props.title}</h1>
                    </div>
                </div>
            </section>
            <section className="section has-text-centered">
                <div className="container">
                    <div className="buttons-container">
                        {props.possibleAnswers.map(a =>
                            <button className={`button is-medium ${answer === a.value ? 'is-primary' : ''}`} onClick={() => setAnswer(a.value)}>{a.label}</button>
                        )}
                    </div>
                    <div className="mt-20">
                        <button className="button is-large is-info" disabled={answer === ''} onClick={() => props.onSaveAnswer(answer)}>Continua</button>
                    </div>
                </div>
            </section>
        </>
    );
}

interface IAnswer {
    gender: string;
    location: string;
    objective: string;
    trainings: string;
}

const Quiz = () => {
    const [step, setStep] = useState(1);
    const [answer, setAnswer] = useState<IAnswer>({
        gender: '',
        location: '',
        objective: '',
        trainings: ''
    });
    const [program, setProgram] = useState('');

    const getPossibleAnswersQ1 = () => {
        return [
            { value: 'male', label: 'Barbat' },
            { value: 'female', label: 'Femeie' }
        ];
    }

    const handleSetAnswerQ1 = (gender: string) => {
        setAnswer(a => ({
            ...a,
            gender: gender
        }));
        setStep(2);
    }

    const getPossibleAnswersQ2 = () => {
        return [
            { value: 'home', label: 'Acasa' },
            { value: 'gym', label: 'Sala de fitness' }
        ];
    }

    const handleSetAnswerQ2 = (location: string) => {
        setAnswer(a => ({
            ...a,
            location: location
        }));
        setStep(3);
    }

    const getPossibleAnswersQ3 = () => {
        if (answer.gender === 'male') {
            if (answer.location === 'home')
                return [
                    { value: 'lose-weight', label: 'Sa slabesc' },
                    { value: 'maintain', label: 'Sa fiu activ si energic' },
                    { value: 'transform', label: 'Sa imi transform corpul' }
                ];
            else if (answer.location === 'gym')
                return [
                    { value: 'lose-weight', label: 'Sa slabesc' },
                    { value: 'develop', label: 'Sa dezvolt masa musculara' },
                    { value: 'transform', label: 'Sa imi transform corpul' }
                ];
        }
        else if (answer.gender === 'female') {
            return [
                { value: 'lose-weight', label: 'Sa slabesc' },
                { value: 'maintain', label: 'Sa fiu activa si energica' },
                { value: 'transform', label: 'Sa imi transform corpul' }
            ];
        }

        return [];
    }

    const handleSetAnswerQ3 = (location: string) => {
        setAnswer(a => ({
            ...a,
            objective: location
        }));
        setStep(4);
    }

    const getPossibleAnswersQ4 = () => {
        if (answer.gender === 'male' && answer.location === 'gym' && (answer.objective === 'develop' || answer.objective === 'transform'))
            return [
                { value: '4', label: 'de 4 ori / saptamana' },
                { value: '3', label: 'de 3 ori / saptamana' },
                { value: '>3', label: 'mai mult de 3 ori / saptamana' }
            ];

        return [
            { value: '3', label: 'de 3 ori / saptamana' },
            { value: '>3', label: 'mai mult de 3 ori / saptamana' }
        ];
    }

    const handleSetAnswerQ4 = (trainings: string) => {
        setAnswer(a => ({
            ...a,
            trainings: trainings
        }));

        setProgram(findPerfectProgram());
        setStep(5);
    }

    const findPerfectProgram = () => {
        if (answer.location === 'home')
            return 'acasa';

        if (answer.gender === 'male') {
            if (answer.objective === 'lose-weight') return 'burn';
            else if (answer.objective === 'develop') return 'gym-pro';
            else if (answer.objective === 'transform') return 'level-up';
        } else if (answer.gender === 'female') {
            if (answer.objective === 'lose-weight') return 'sparkle';
            else if (answer.objective === 'maintain') return 'refresh';
            else if (answer.objective === 'transform') return 'shape-it';

        }
        return 'fit-360';
    }

    return (
        <>
            {step === 1 &&
                <Question
                    title="Care este sex-ul tau?"
                    possibleAnswers={getPossibleAnswersQ1()}
                    onSaveAnswer={handleSetAnswerQ1}
                />
            }

            {step === 2 &&
                <Question
                    title="Unde vrei sa te antrenezi?"
                    possibleAnswers={getPossibleAnswersQ2()}
                    onSaveAnswer={handleSetAnswerQ2}
                />
            }

            {step === 3 &&
                <Question
                    title="Care este obiectivul tau?"
                    possibleAnswers={getPossibleAnswersQ3()}
                    onSaveAnswer={handleSetAnswerQ3}
                />
            }

            {step === 4 &&
                <Question
                    title="Care este disponibilitatea ta de a te antrena?"
                    possibleAnswers={getPossibleAnswersQ4()}
                    onSaveAnswer={handleSetAnswerQ4}
                />
            }

            {step === 5 &&
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-half">
                            <div className="card has-text-centered">
                                <div className="card-image">
                                    <img src="https://fitnessdesigner.ro/site/wp-content/uploads/2018/02/logo-fitnessdesigner-400x130.png" alt="" />
                                </div>
                                <div className="card-content">
                                    <div className="content">
                                        <h2 className="title">{program}</h2>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Phasellus nec iaculis mauris.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Quiz;